import React from 'react'

const SecureFooter = () => {
  return (
    <div className='row securefooter text-center'>
      <div className='col-12 bg-info bg-opacity-10 py-2'>
            <div>Copyright - HashedBit</div>
      </div>
    </div>
  )
}

export default SecureFooter
